require("@rails/ujs").start()

require('jquery')
import $ from 'jquery';
global.$ = $;
global.jQuery = $;

require("terminal/menu_toggler")
require("terminal/bread_seller")
require("terminal/bread_form")
require("terminal/leftovers_tracker")
